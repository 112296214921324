import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FeaturedProjectsProps } from './FeaturedProjects.props';
import LinkChildren from '@atoms/LinkChildren/LinkChildren';
import CustomCard from '@atoms/CustomCard/CustomCard';
import Row from '@atoms/CustomGrid/Row';
import { useCarousel } from '@hooks/useCarousel';
import CarouselButtons from '@molecules/CarouselActions/CarouselButtons';

const FeaturedProjects: React.FC<FeaturedProjectsProps> = (props) => {
  const {
    items,
    className,
    xsColumnSize = 8,
    moveTop = true,
    overlayBoxPadding = 16,
    lgColumnSize = 4,
    carouselButtonsSx,
    colorVariant,
    sx = {},
    isDraggableOnLg = false,
    slidesToScrollOnLg = 2,
    showCarouselButtons = false,
    changeMenuNameHandler,
  } = props;

  const { emblaRef, emblaApi, selectedIndex, scrollSnaps } = useCarousel({
    breakpoints: {
      '(min-width: 992px)': {
        draggable: isDraggableOnLg,
        slidesToScroll: slidesToScrollOnLg,
      },
    },
    slidesToScroll: 1,
  });

  if (!items.length) return;

  return (
    <>
      <Box
        ref={emblaRef}
        mt={-48}
        pt={48}
        className={className}
        position="relative"
        overflow={{ xs: 'visible', lg: 'hidden' }}
      >
        <Row flexWrap="nowrap">
          {items.map((item, index) => (
            <Grid key={index} sx={{ flexShrink: 0 }} item xs={xsColumnSize} lg={lgColumnSize}>
              <LinkChildren isExternal={item.cta?.isExternal} target={item.cta?.target} url={item.cta?.url}>
                <CustomCard
                  sx={{
                    position: 'relative',
                    height: { xs: 188, lg: 216 },
                    top: moveTop && (index % 2 !== 0 ? -40 : 0),
                    ...sx,
                  }}
                  onClick={() => changeMenuNameHandler(null)}
                  overlayBoxPadding={overlayBoxPadding}
                  caption={item.caption}
                  imageUrl={item.imageUrl}
                  label={item.title}
                  variant="with-background-title-and-forward-arrow"
                  isExternalLink={!!item.cta?.isExternal}
                />
              </LinkChildren>
            </Grid>
          ))}
        </Row>
      </Box>
      {items.length > 2 && showCarouselButtons && (
        <CarouselButtons
          emblaApi={emblaApi}
          sx={carouselButtonsSx}
          scrollSnapsLength={scrollSnaps.length}
          colorVariant={colorVariant}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};

export default FeaturedProjects;
